import { Injectable } from '@angular/core';
import { IReviewSessionComment, IUpdateCommentsRequest, IReviewSessionCommentLite, IReviewSessionResponseList, ISingleCommentRequest, IWorkflowReviewLockResponse, IDeleteCommentRequest, IToggleSolveCommentRequest, ICreateReplyRequest, isCellHighlight } from '@core/interfaces/review.interface';
import { WorkflowRuntimeModel } from '@core/models';
import { HelperService, HighlightService, RoutingService, RuntimeService, ValidationService, SdoxReviewsIdleService } from '@core/services';
import { ReviewService } from '@core/services/review.service';
import { TraceMatrixService } from '@core/services/trace-matrix.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { GetTasks } from '@store/tasks/tasks.actions';
import * as _ from 'lodash';
import { cloneDeep, sortBy } from 'lodash';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { UserSelectors } from 'src/app/store/user/user.selectors';
import { IDropdownButtonMenu, IValidationResponse, IWorkflowRuntime, RuntimeWorkflowStatus } from '../../core/interfaces';
import {
  AddComment, AddReply, BulkUpdateComments, DeleteComment, DeleteReply,
  EditComment, EditReply,
  ExtendLock,
  FinalizeReview,
  GenerateStepUuid,
  GetReviewSessions,
  GetSessionComments, Lock,
  PostComment,
  PostReply,
  RemoveComment,
  RemoveReply,
  SaveWorkflow,
  SelectComment,
  SelectCommentChunk,
  SetActionsReviewVersions,
  SetCurrentDocument,
  SetGeneralFeEnableStatus,
  SetLinksLoading,
  SetLoadingDocument,
  SetLoadingHighlights,
  SetLockValue,
  SetReviewVersionsBtnLabelAndComments,
  SolveComment,
  StopLinksStatusCheck,
  Unlock, UpdateComment, UpdateCurrentReviewSession, UpdateDocument, UpdateReply, UpdateTablePageNumber
} from './document.actions';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DocumentSelectors } from './document.selectors';

export interface DocumentStateModel {
  loadingDocument: boolean;
  loadingHighlights: boolean;
  loadingLinks: boolean;
  loadingComments: boolean;
  statusLoaded: boolean; // "true" if we know the status from the API; "false" until we get the status from the API
  document: IWorkflowRuntime;
  reviewSessions: IReviewSessionResponseList;
  reviewSessionComments: IReviewSessionComment[];
  updatedComments: IReviewSessionComment[];
  selectedComment: IReviewSessionComment;
  reviewVersionsBtnLabel: string;
  commentCount: string;
  actionsReviewVersions: IDropdownButtonMenu[];
  tablePageNumbers: {};
  lockStatus: IWorkflowReviewLockResponse | null;
  lockedFromThisTab: boolean;
  generalFeEnableStatus: {}
}

const defaults = {
  loadingDocument: false,
  loadingHighlights: false,
  loadingLinks: false,
  loadingComments: false,
  statusLoaded: false,
  document: null,
  reviewSessions: null,
  reviewSessionComments: [],
  updatedComments: [],
  selectedComment: null,
  reviewVersionsBtnLabel: null,
  commentCount: null,
  actionsReviewVersions: [],
  lockStatus: null,
  tablePageNumbers: {},
  lockedFromThisTab: false,
  generalFeEnableStatus: {}
};

@State<DocumentStateModel>({
  name: 'DocumentState',
  defaults
})
@Injectable()
export class DocumentState {

  constructor(
    private reviewService: ReviewService,
    private store: Store,
    private traceMatrixService: TraceMatrixService,
    private runtimeService: RuntimeService,
    private helperService: HelperService,
    private toastr: ToastrService,
    private validationService: ValidationService,
    private router: Router,
    private routingService: RoutingService,
    private highlightService: HighlightService,
    private sdoxReviewsIdleService: SdoxReviewsIdleService
  ) {
  }


  @Action(SetLoadingDocument, { cancelUncompleted: true })
  setLoadingDocument({ patchState }: StateContext<DocumentStateModel>, { isLoading }) {
    patchState({
      loadingDocument: isLoading
    });
  }

  @Action(SetLoadingHighlights, { cancelUncompleted: true })
  setLoadingHighlights({ patchState }: StateContext<DocumentStateModel>, { isLoading }) {
    patchState({
      loadingHighlights: isLoading
    });
  }

  @Action(SetLinksLoading, { cancelUncompleted: true })
  setLinksLoading({ patchState }: StateContext<DocumentStateModel>, { isLoading }) {
    patchState({
      loadingLinks: isLoading
    });
  }

  @Action([GetReviewSessions, GetSessionComments, EditComment, PostComment, DeleteComment])
  showLoadingSpinner({ patchState }: StateContext<DocumentStateModel>) {
    patchState({
      loadingHighlights: true
    });
  }

  @Action([AddComment, RemoveComment])
  hideLoadingSpinner({ patchState }: StateContext<DocumentStateModel>) {
    patchState({
      loadingHighlights: false
    });
  }

  @Action(GetReviewSessions, { cancelUncompleted: true })
  getReviewSessions({ patchState, dispatch, getState }: StateContext<DocumentStateModel>, { workflowUuid, versionUuid, loadComments }) {
    const document = getState().document;
    patchState({
      loadingComments: true,
      reviewSessions: null
    });
    return this.reviewService.getReviewSessions(workflowUuid, versionUuid).pipe(
      tap((result: IReviewSessionResponseList) => {
        patchState({
          reviewSessions: result
        });
        const isReviewDocument = document.version_root_uuid !== workflowUuid;
        if (result.sessions[result.sessions.length - 1] && loadComments && isReviewDocument) {
          dispatch(new GetSessionComments(document.uuid));
        } else {
          patchState({
            loadingComments: false
          });
          dispatch(new SetLoadingHighlights(false));
        }
      })
    );
  }

  @Action(UpdateCurrentReviewSession, { cancelUncompleted: true })
  updateCurrentReviewSession({ patchState, getState }: StateContext<DocumentStateModel>) {
    const clonedReviewSessions = cloneDeep(getState().reviewSessions);
    const commentsLength = getState().reviewSessionComments.length;
    const index = clonedReviewSessions.sessions.findIndex(rs => rs.status === RuntimeWorkflowStatus.InReview);
    clonedReviewSessions.sessions[index].comments_count = commentsLength;
    patchState({
      reviewSessions: clonedReviewSessions
    });
  }

  @Action(SetCurrentDocument)
  setCurrentDocument({ patchState, setState, dispatch }: StateContext<DocumentStateModel>, { document }) {
    setState(defaults);
    patchState({ document: cloneDeep(document) });
    dispatch(new SetLockValue(document.review_lock));
  }

  @Action(GetSessionComments, { cancelUncompleted: true })
  getSessionComments({ patchState, dispatch }: StateContext<DocumentStateModel>, { sessionUuid }) {
    patchState({
      loadingComments: true
    });
    return this.reviewService.getReviewSessionComments(sessionUuid).pipe(
      tap((result: IReviewSessionComment[]) => {
        patchState({
          loadingComments: false,
          reviewSessionComments: this.orderReplies(result)
        });
        dispatch(new SetLoadingHighlights(false));
      }));
  }

  @Action(SelectComment)
  selectComment({ getState, patchState }: StateContext<DocumentStateModel>, { selectedCommentGid }: SelectComment) {
    const state = getState();
    const selectedComment = state.reviewSessionComments
      .find((comment: IReviewSessionComment) => comment.gid === selectedCommentGid);
    patchState({ selectedComment });
  }

  @Action(SelectCommentChunk)
  selectCommentChunk({ getState, patchState }: StateContext<DocumentStateModel>, { selectedChunkGid }: SelectCommentChunk) {
    const state = getState();
    const commentsThatHaveThisClickedElement = state.reviewSessionComments
      .filter((comment: IReviewSessionComment) => comment.composed_highlights.map(chunk => chunk.gid).find(id => id === selectedChunkGid));
    const smallestCommentThatHasThisClickedElement = _.cloneDeep(commentsThatHaveThisClickedElement
      .reduce((a: IReviewSessionComment, b: IReviewSessionComment) =>
        b.composed_highlights.length <= a.composed_highlights.length
          && b.selected_text.length <= a.selected_text.length ? b : a
      ));
    patchState({ selectedComment: smallestCommentThatHasThisClickedElement });
  }

  @Action(AddComment)
  addComment({ getState, patchState, dispatch }: StateContext<DocumentStateModel>, action: AddComment) {
    patchState({
      reviewSessionComments: [...getState().reviewSessionComments, action.newComment],
      selectedComment: action.newComment
    });
    dispatch(new SelectComment(action.newComment.gid));
  }

  @Action(UpdateComment)
  updateComment(ctx: StateContext<DocumentStateModel>, action: UpdateComment) {
    const clonedComment: IReviewSessionComment = cloneDeep(action.comment);
    clonedComment.text = action.text !== undefined && action.text !== null ? action.text : clonedComment.text;
    ctx.setState(
      patch({
        reviewSessionComments: updateItem(comment => comment.gid === action.comment.gid, clonedComment)
      })
    );
  }

  @Action(RemoveComment)
  removeComment(ctx: StateContext<DocumentStateModel>, action: RemoveComment) {
    this.store.dispatch(new Unlock());
    this.removeCommentFromUI(action.comment);
    ctx.setState(
      patch({
        reviewSessionComments: removeItem((comment: IReviewSessionComment) => comment.gid === action.comment.gid),
      })
    );
  }

  @Action(PostComment)
  postComment(ctx: StateContext<DocumentStateModel>, action: PostComment) {
    const reviewSessionUuid = ctx.getState().lockStatus?.uuid;
    if (!reviewSessionUuid && action.retryOn404) {
      // Dispatch Lock, after that post comment. Use setTimeout to make sure the store is updated
      ctx.dispatch(new Lock(true)).subscribe(() => setTimeout(() => ctx.dispatch(new PostComment(action.comment, false))))
      return;
    }

    const updatedComments = this.getCommentsForUpdate(ctx.getState().reviewSessionComments, action.comment)
    const commentRequest: ISingleCommentRequest = {
      workflow_review_lock_uuid: reviewSessionUuid,
      comment: action.comment,
      updates: updatedComments
    }

    const workflowReviewVersionUuid = ctx.getState().document.uuid;
    this.reviewService.postComment(workflowReviewVersionUuid, commentRequest)
      .subscribe(
        res => {
          ctx.setState(
            patch({
              reviewSessionComments: updateItem(comment => comment.gid === res.gid, res)
            })
          );
          ctx.dispatch(new UpdateCurrentReviewSession());
          this.sdoxReviewsIdleService.stopIdleTimer();
        },
        (err) => {
          if (err.status === 404 && action.retryOn404) {
            // Dispatch Lock, after that post comment. Use setTimeout to make sure the store is updated
            ctx.dispatch(new SetLockValue(null));
            ctx.dispatch(new Lock(true)).subscribe(() => setTimeout(() => ctx.dispatch(new PostComment(action.comment, false))))
          } else {
            ctx.dispatch(new RemoveComment(action.comment))
          }
        }
      );
  }

  @Action(EditComment)
  editComment(ctx: StateContext<DocumentStateModel>, action: EditComment) {
    const requestData = this.getRequestData(ctx);
    this.reviewService.editComment(requestData.versionUuid, action.comment.uuid, { text: action.comment.text })
      .pipe(finalize(() => ctx.dispatch(new SetLoadingHighlights(false))))
      .subscribe(
        res => {
          ctx.setState(
            patch({
              reviewSessionComments: updateItem(comment => comment.gid === res.gid, res)
            })
          );
        });
  }

  @Action(DeleteComment)
  deleteComment(ctx: StateContext<DocumentStateModel>, action: DeleteComment) {
    const requestData = this.getRequestData(ctx);
    const deletedComment = action.comment;
    this.removeCommentFromUI(action.comment);
    const updatedComments = this.getCommentsForUpdate(ctx.getState().reviewSessionComments, deletedComment);
    const request: IDeleteCommentRequest = {
      workflow_review_lock_uuid: requestData.sessionUuid,
      updates: updatedComments
    }

    this.reviewService.deleteComment(requestData.versionUuid, deletedComment.uuid, request)
      .subscribe(
        () => {
          const deletedStepUuid = deletedComment.step_uuid;
          const currentComments = _.cloneDeep(ctx.getState().reviewSessionComments).filter(comment => comment.step_uuid === deletedStepUuid);
          const commentsUpdated = this.highlightService.getBulkUpdateComments(deletedComment).filter(comm =>
            (comm.gid && deletedComment.gid && comm.gid !== deletedComment.gid)
            || (deletedComment.uuid && comm.uuid && (comm.uuid !== deletedComment.uuid)));

          commentsUpdated.forEach(comment => {
            const index = currentComments.findIndex(c => c.gid === comment.gid);
            if (index !== -1) {
              currentComments[index] = comment;
            }
          });
          ctx.patchState({
            reviewSessionComments: [...currentComments]
          });
          ctx.dispatch(new UpdateCurrentReviewSession());
          this.store.dispatch(new SelectComment(null));
          this.sdoxReviewsIdleService.stopIdleTimer();
        },
        (err) => {
          if (err.status === 404 && action.retryOn404) {
            // Dispatch Lock, after that post comment. Use setTimeout to make sure the store is updated
            ctx.dispatch(new SetLockValue(null));
            ctx.dispatch(new Lock(true)).subscribe(() => setTimeout(() => ctx.dispatch(new DeleteComment(deletedComment, false))))
          }
        }
      );
  }

  @Action(SolveComment)
  solveComment(ctx: StateContext<DocumentStateModel>, action: SolveComment) {
    const requestData = this.getRequestData(ctx);
    const request: IToggleSolveCommentRequest = {
      solved: !action.comment.is_solved
    }
    this.reviewService.solveComment(requestData.versionUuid, action.comment.uuid, request)
      .subscribe(
        res => {
          const clonedComment: IReviewSessionComment = cloneDeep(action.comment);
          clonedComment.is_solved = request.solved;
          ctx.setState(
            patch({
              reviewSessionComments: updateItem(comment => comment.gid === action.comment.gid, clonedComment)
            })
          );
        }
      );
  }

  @Action(BulkUpdateComments)
  bulkUpdateComments(ctx: StateContext<DocumentStateModel>, action: BulkUpdateComments) {
    // Merge any comment "in progress"
    const reviewSessionCommentsInitial = [...ctx.getState().reviewSessionComments];
    const commentWithNoUuid = reviewSessionCommentsInitial?.find(x => !x.uuid);
    const newComments = _.cloneDeep(action.comments);
    const commentsForOtherSteps = _.cloneDeep(reviewSessionCommentsInitial.filter(x => x.step_uuid !== action.stepUuid));

    const duplicateCommOfNew = commentWithNoUuid ? newComments.find(x => _.isEqual(x.composed_highlights, commentWithNoUuid.composed_highlights)) : null;
    if (commentWithNoUuid && !duplicateCommOfNew) {
      newComments.push(commentWithNoUuid)
    }
    ctx.patchState({
      reviewSessionComments: [...commentsForOtherSteps, ...newComments]
    });
  }

  @Action(AddReply)
  addReply(ctx: StateContext<DocumentStateModel>, action: AddReply) {
    const currentUser = this.store.selectSnapshot(UserSelectors.getCurrentUser);
    const newReply: IReviewSessionCommentLite = {
      text: '',
      user: {
        ...currentUser,
        uuid: currentUser.uuid,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        email: currentUser.email,
        image_url: currentUser.image_url
      }
    };
    const changedComment: IReviewSessionComment = {
      ...action.comment,
      replies: [...action.comment.replies, newReply]
    };
    ctx.setState(
      patch({
        reviewSessionComments: updateItem(comment => comment.gid === action.comment.gid, changedComment)
      })
    );
  }

  @Action(RemoveReply)
  removeReply(ctx: StateContext<DocumentStateModel>, action: RemoveReply) {
    const cloned = cloneDeep(action.comment);
    const changedComment: IReviewSessionComment = {
      ...cloned,
      replies: cloned.replies.splice(0, cloned.replies.length - 1)
    };
    ctx.setState(
      patch({
        reviewSessionComments: updateItem(comment => comment.gid === action.comment.gid, changedComment)
      })
    );
  }

  @Action(UpdateReply)
  updateReply(ctx: StateContext<DocumentStateModel>, action: UpdateReply) {
    const clonedComment: IReviewSessionComment = cloneDeep(action.parentComment);
    const replyIndex = clonedComment.replies.findIndex(reply => reply.uuid === action.reply.uuid);
    clonedComment.replies[replyIndex] = {
      ...clonedComment.replies[replyIndex],
      text: action.text
    };
    ctx.setState(
      patch({
        reviewSessionComments: updateItem(comment => comment.gid === action.parentComment.gid, clonedComment)
      })
    );
  }

  @Action(EditReply)
  editReply(ctx: StateContext<DocumentStateModel>, action: EditReply) {
    const requestData = this.getRequestData(ctx);
    this.reviewService.editReply(requestData.versionUuid, action.reply.uuid, { text: action.reply.text })
      .subscribe(
        res => {
          ctx.setState(
            patch({
              reviewSessionComments: updateItem(comment => comment.gid === action.parentComment.gid, res)
            })
          );
        }
      );
  }

  @Action(DeleteReply)
  deleteReply(ctx: StateContext<DocumentStateModel>, action: DeleteReply) {
    const requestData = this.getRequestData(ctx);
    this.reviewService.deleteReply(requestData.versionUuid, action.reply.uuid)
      .subscribe(
        () => {
          const clonedComment = cloneDeep(action.parentComment);
          clonedComment.replies = clonedComment.replies.filter(reply => reply.uuid !== action.reply.uuid);
          ctx.setState(
            patch({
              reviewSessionComments: updateItem(comment => comment.gid === action.parentComment.gid, clonedComment)
            })
          );
        }
      );
  }

  @Action(PostReply)
  postReply(ctx: StateContext<DocumentStateModel>, action: PostReply) {
    const requestData = this.getRequestData(ctx);
    const request: ICreateReplyRequest = {
      comment_uuid: action.parentComment.uuid,
      text: action.reply.text
    }

    this.reviewService.postReply(requestData.versionUuid, request)
      .subscribe(
        res => {
          ctx.setState(
            patch({
              reviewSessionComments: updateItem(comment => comment.gid === action.parentComment.gid, this.orderReply(res))
            })
          );
        }
      );
  }

  @Action(SetReviewVersionsBtnLabelAndComments)
  setReviewVersionsBtnLabelAndComments({ patchState }: StateContext<DocumentStateModel>, action: SetReviewVersionsBtnLabelAndComments) {
    patchState({
      reviewVersionsBtnLabel: action.label,
      commentCount: action.comments,
    });
  }

  @Action(SetActionsReviewVersions)
  setActionsReviewVersions({ patchState }: StateContext<DocumentStateModel>, action: SetActionsReviewVersions) {
    patchState({ actionsReviewVersions: action.reviewVersions });
  }

  @Action(Lock)
  lock(ctx: StateContext<DocumentStateModel>, action: Lock) {
    if (!this.store.selectSnapshot(UserSelectors.isReviewLockedByCurrentUser)) {
      // Set lockedFromThisTab before lock call:
      ctx.patchState({
        lockedFromThisTab: action.lockedFromThisTab
      });
      ctx.dispatch(new SetLoadingHighlights());
      const requestData = this.getRequestData(ctx);
      return this.reviewService.lock(requestData.versionUuid).pipe(
        tap((lockStatus: IWorkflowReviewLockResponse) => {
          ctx.patchState({
            lockStatus,
            statusLoaded: true,
          });
          ctx.dispatch(new SetLoadingHighlights(false));
          this.sdoxReviewsIdleService.startIdleTimer();
          this.sdoxReviewsIdleService.startLockStatusExpire(new Date(lockStatus.expires_at));
        }),
        catchError(() => ctx.dispatch(new SetLoadingHighlights(false)))
      );
    } else {
      ctx.dispatch(new ExtendLock());
    }
  }

  @Action(Unlock)
  unlock(ctx: StateContext<DocumentStateModel>, action: Unlock) {
    if (this.store.selectSnapshot(UserSelectors.isReviewLockedByCurrentUser)) {
      ctx.dispatch(new SetLoadingHighlights());
      const requestData = this.getRequestData(ctx);
      const reviewSessionUuid = ctx.getState().lockStatus?.uuid;
      return this.reviewService.unlock(requestData.versionUuid, reviewSessionUuid, action.useKeepAlive).pipe(
        tap(response => {
          this.sdoxReviewsIdleService.stopIdleTimer();
          this.sdoxReviewsIdleService.stopLockStatusExpire();
          ctx.patchState({
            lockStatus: null,
            statusLoaded: true,
            lockedFromThisTab: false
          });
          ctx.dispatch(new SetLoadingHighlights(false));
          ctx.getState().reviewSessionComments.forEach(c => {
            //  Get the comment which contains the unsaved reply
            const replyToDelete = c.replies?.find(r => !r.uuid);
            if (replyToDelete) {
              ctx.dispatch(new RemoveReply(c));
            }
          });
        }),
        catchError(() => {
          ctx.patchState({
            lockStatus: null,
            statusLoaded: true
          });
          return ctx.dispatch(new SetLoadingHighlights(false))
        })
      );
    }
  }

  @Action(ExtendLock)
  extendLock(ctx: StateContext<DocumentStateModel>, action: ExtendLock) {
    if (this.store.selectSnapshot(UserSelectors.isReviewLockedByCurrentUser)) {
      if (!action.silentCall) {
        ctx.dispatch(new SetLoadingHighlights());
      }
      const requestData = this.getRequestData(ctx);

      return this.reviewService.extendReviewSession(requestData.versionUuid, requestData.sessionUuid).pipe(
        tap(res => {
          ctx.patchState({
            statusLoaded: true
          });
          ctx.dispatch(new SetLoadingHighlights(false));
          if (!action.silentCall) {
            this.sdoxReviewsIdleService.startIdleTimer();
          }
        }),
        catchError(() => {
          ctx.dispatch(new SetLockValue(null))
          return ctx.dispatch(new SetLoadingHighlights(false))
        })
      );
    }
  }

  /**
   * Called when new value for lockStatus comes via sockets
   * @param ctx 
   * @param action 
   */
  @Action(SetLockValue)
  setLockValue(ctx: StateContext<DocumentStateModel>, action: SetLockValue) {
    if (action.lockValue?.expires_at) {
      this.sdoxReviewsIdleService.startLockStatusExpire(new Date(action.lockValue.expires_at));
    } else {
      this.sdoxReviewsIdleService.stopLockStatusExpire();
    }
    ctx.patchState({
      lockStatus: action.lockValue,
      statusLoaded: true
    });
  }

  @Action(FinalizeReview, { cancelUncompleted: true })
  finalizeReview(ctx: StateContext<DocumentStateModel>, action: FinalizeReview) {
    const document = ctx.getState().document;
    const requestData = this.getRequestData(ctx);
    const sessionsData = ctx.getState().reviewSessions;
    const currentSession = sessionsData?.sessions?.find(session => session.uuid === document.uuid);
    const versionUuid = sessionsData?.original_workflow?.uuid;

    return this.reviewService.finalizeReview(requestData.workflowUuid, versionUuid, currentSession.uuid).pipe(
      tap(() => {
        this.toastr.success('Review successfully finalized!');
        this.toastr.warning('Your review task has been canceled or is done!');
        this.validationService.checkAccessForDocuments([action.originalUuid])
          .subscribe((_: IValidationResponse): void => {
            this.router.navigate([this.routingService.HOME.url()]);
          });
        ctx.dispatch(new GetTasks(0, Number.MAX_SAFE_INTEGER))
      })
    );
  }

  @Action(UpdateTablePageNumber)
  updateTablePageNumber(ctx: StateContext<DocumentStateModel>, action: UpdateTablePageNumber) {
    const pageNumbers = ctx.getState().tablePageNumbers;
    ctx.setState(
      patch({
        tablePageNumbers: {
          ...pageNumbers,
          [action.stepUuid]: action.pageNumber
        }
      })
    );
  }

  private getRequestData(ctx: StateContext<DocumentStateModel>): { workflowUuid: string, versionUuid: string, sessionUuid: string } {
    const document = ctx.getState().document;
    const sessionsData = ctx.getState().reviewSessions;
    const lockStatusSessionUuid = ctx.getState().lockStatus?.uuid;

    return {
      workflowUuid: sessionsData?.original_workflow?.version_root_uuid,
      versionUuid: document.uuid,
      sessionUuid: lockStatusSessionUuid
    };
  }

  private orderReplies(comments: IReviewSessionComment[]): IReviewSessionComment[] {
    return comments.map((comment: IReviewSessionComment) => this.orderReply(comment));
  }

  private orderReply(comment: IReviewSessionComment): IReviewSessionComment {
    return {
      ...comment,
      replies: sortBy(comment.replies, (reply: IReviewSessionCommentLite) => new Date(reply.created_at))
    };
  }

  @Action(StopLinksStatusCheck, { cancelUncompleted: true })
  stopLinksStatusCheck({ }: StateContext<DocumentStateModel>) {
    return this.traceMatrixService.stopLinksStatusCheck();
  }

  @Action(UpdateDocument, { cancelUncompleted: true })
  updateDocument({ patchState }: StateContext<DocumentStateModel>, { document }: UpdateDocument) {
    patchState({
      document: document,
    });
  }

  @Action(GenerateStepUuid, { cancelUncompleted: true })
  generateStepUuid({ getState, dispatch }: StateContext<DocumentStateModel>) {
    dispatch(new SetLoadingDocument(true));
    const wf = _.cloneDeep(getState().document);
    this.helperService.cleanGeneralFEsNullTags(wf);
    return this.runtimeService.updateRuntimeWorkflow(wf.version_root_uuid, wf.uuid,
      new WorkflowRuntimeModel(wf).toDTO()).pipe(
        take(1),
        map(res => res.data),
        switchMap(document => {
          return dispatch([new UpdateDocument(document), new SetLoadingDocument(false)])
        })
      )
  }

  @Action(SaveWorkflow, { cancelUncompleted: true })
  saveWorkflow({ getState, dispatch }: StateContext<DocumentStateModel>) {
    dispatch(new SetLoadingDocument(true));
    const wf = _.cloneDeep(getState().document);
    this.helperService.cleanGeneralFEsNullTags(wf);
    return this.runtimeService.updateRuntimeWorkflow(wf.version_root_uuid, wf.uuid,
      new WorkflowRuntimeModel(wf).toDTO()).pipe(
        take(1),
        map(res => res.data),
        tap(res => dispatch([new UpdateDocument(res), new SetLoadingDocument(false)]))
      )
  }

  @Action(SetGeneralFeEnableStatus, { cancelUncompleted: true })
  setGeneralFeEnableStatus({ patchState, getState }: StateContext<DocumentStateModel>, { stepUuid, enable }: SetGeneralFeEnableStatus) {
    patchState({
      generalFeEnableStatus: { ...getState().generalFeEnableStatus, [stepUuid]: enable }
    });
  }

  /**
   * Replaces the bulk action from state
   * @param reviewSessionComments 
   * @param comment 
   * @returns 
   */
  private getCommentsForUpdate(reviewSessionComments: IReviewSessionComment[], comment: IReviewSessionComment) {
    const bulkUpdatedComments = this.highlightService.getBulkUpdateComments(comment);
    const updatedComments: IUpdateCommentsRequest[] = [];
    reviewSessionComments = reviewSessionComments.map(comment => {
      const actionComment = bulkUpdatedComments.find(actionC => comment.gid === actionC.gid);
      if (actionComment) {
        const updatedComment = {
          ...comment,
          ...actionComment
        };
        updatedComments.push({
          uuid: comment.uuid,
          order_index: actionComment.order_index,
          composed_highlights: actionComment.composed_highlights
        });
        return updatedComment;
      }
      return comment;
    });
    return updatedComments.filter(c => !!c.uuid && c.uuid !== comment.uuid);
  }

  /**
   * Check if comment is part of text or table cell
   * @param comment 
   */
  private removeCommentFromUI(comment: IReviewSessionComment) {
    const reviewTarget = this.highlightService.getReviewTargetFn(comment);
    const tablePageNumber = this.store.selectSnapshot(DocumentSelectors.getTablePageNumber(comment.step_uuid));
    const commentPageNumber = this.store.selectSnapshot(DocumentSelectors.getCommentPageNumber(comment));

    if (commentPageNumber !== null) {
      if (commentPageNumber === tablePageNumber) {
        if (isCellHighlight(comment)) {
          this.highlightService.removeCellComment(reviewTarget, comment);
        } else {
          this.highlightService.removeTextComment(reviewTarget, comment);
        }
      }
    } else {
      this.highlightService.removeTextComment(reviewTarget, comment);
    }
  }
}
