import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company, IUser } from '../models';
import { ApiService } from './api.service';
import { AsyncFileExportType } from '@store/async-pdf/async-pdf.model';
import { HttpClient } from '@angular/common/http';
import { AuditTrailAdminPrintingData } from 'src/app/admin/pages/audit-trail/audit-trail.interface';
import { AuditTrailPrintingData } from 'src/app/audit-trail/audit-trail.interface';
import { ReportPrintingData } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PrintingService {

  constructor(private apiService: ApiService,
              private http: HttpClient) {}

  public printTable(tableData) {
    return this.apiService.postToPdfService('/print-table', tableData);
  }

  public printTableAsyncAT(tableData, company: Company, user: IUser, auditTrailData: AuditTrailPrintingData | AuditTrailAdminPrintingData, columnsSplit: number[] = []) {
    return this.apiService.postToPdfService('/print-table-async', {tableData, company, user, auditTrailData, columnsSplit});
  }

  public printTableAsyncReports(tableData, company: Company, user: IUser, reportData: ReportPrintingData, columnsSplit: number[] = []) {
    return this.apiService.postToPdfService('/print-table-async', {tableData, company, user, reportData, columnsSplit});
  }

  public printSDOXDocument<T>(document: T, company: Company, user: IUser, async = true, exportType = AsyncFileExportType.Pdf, isArchiveOrPrinting = true) {
    return this.apiService.postToPdfService('/print-sdox', {document, company, user, async, exportType, isArchiveOrPrinting});
  }

  public printWorkflow<T>(workflow: T, company: Company, user: IUser, exportType = AsyncFileExportType.Pdf, isArchiveOrPrinting = true) {
    return this.apiService.postToPdfService('/print-workflow', {workflow, company, user, exportType, isArchiveOrPrinting});
  }

  public saveDocumentAsPDF(fileName, bytes) {
    const blob = new Blob([bytes], {type: 'application/pdf'});
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).navigator.msSaveBlob(blob, fileName + '.pdf');
    } else {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.target = '_blank';
      link.click();
      document.body.removeChild(link);
    }
  }

  public sendTableByEmail(tableData, company: Company, emails, user: IUser, filename) {
    return this.apiService.postToPdfService('/send-by-email',
        {tableData, company, emails, user, type: 'table', filename}, 'json')
        .pipe(catchError(error => this.handleError(error)));
  }

  public downloadPackageFiles(packageData, company: Company, user: IUser, isDownloadAllFiles: boolean) {
    return this.apiService.postToPdfService('/download-package-files', {packageData, company, user, isDownloadAllFiles})
        .pipe(catchError(error => this.handleError(error)));
  }

  public downloadAttachments(attachmentData, company: Company, user: IUser) {
    return this.apiService.postToPdfService('/download-attachments', {attachmentData, company, user})
        .pipe(catchError(error => this.handleError(error)));
  }

  // [NRESQ-4147] Hide a specific error which is returned from the API even when the email is sent
  private handleError(error) {
    if (error?.error?.message?.includes('Protocol error (IO.close)')) {
      return of([]);
    }
    throw throwError(error);
  }

  getImage(imageUrl: string) {
    return this.http.get(imageUrl, { responseType: 'blob' })
    .pipe(catchError((err) => throwError(err)));
  }
}
